.img-bundle-new {
    background-image: url('./img/entity-tab-bundle-new.png');
    padding-top: 19.06746548580359%; /* image is 3839x732 */
}

.img-bundle-export-new {
    background-image: url('./img/entity-tab-bundle_export-new.png');
    padding-top: 54.6875%; /* image is 3840x2100 */
}

.img-case-user-new {
    background-image: url('./img/entity-tab-case_user-new.png');
    padding-top: 22.39583333333333%; /* image is 3840x860 */
}

.img-case-man {
    background-image: url('./img/entity-tab-case_man.png');
    padding-top: 54.6875%; /* image is 3840x2100 */
}

.img-landing {
    background-image: url('./img/entity-tab-landing.png');
    padding-top: 54.6875%; /* image is 3840x2100 */
    /*height: 500px;*/
}

.img-uploader-config {
    background-image: url('./img/entity-tab-uploader-config.png');
    padding-top: 54.6875%; /* image is 3840x2100 */
}

.img-report-spec-new {
    background-image: url('./img/entity-tab-report_spec-new.png');
    padding-top: 20.28645833333333%; /* image is 3840x779 */
}

.img-report {
    background-image: url('./img/Entity_WHT_Small.png');
    padding-top: 33.1476323%; /* image is 359x119 */
    background-color: '#3d4977';
}

.img-pdf-layer-new {
    background-image: url('./img/entity-tab-pdf_layer-new.png');
    padding-top: 19.07243355914539%; /* image is 3838x732 */
}

.img-notifs-objects {
    background-image: url('./img/entity-tab-notifs-objects.png');
    padding-top: 54.6875%; /* image is 3840x2100 */
}

.img-hyperlink-objects {
    background-image: url('./img/entity-tab-hyperlink-objects.png');
    padding-top: 54.6875%; /* image is 3840x2100 */
}
